import './video.scss';

import { tryParseJSON, createElementFromHTML } from './../../utilities/js/helper';

class Video {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-video',
            triggerAttr: 'trigger',
            pagerAttr: 'pager',
            templateAttr: 'data-video-template'
        };

        this.settings = Object.assign({}, defaults, options);

        this.$video = $element;
        this.$pager = this.$video.querySelector(`[${this.settings.initAttr}="${this.settings.pagerAttr}"]`);
        this.$trigger = this.$pager.querySelector(`[${this.settings.initAttr}="${this.settings.triggerAttr}"]`);
        this.videos = this.$pager.getAttribute(this.settings.templateAttr);

        if (this.videos) {
            this.videos = tryParseJSON(this.videos);
        }
    }

    initialize () {
        if (this.$pager && this.$trigger) {
            this.setEvents();
        }
    }

    setEvents () {
        this.$trigger.addEventListener('click', (e) => {
            this.loadMoreVideos();
            e.preventDefault();
        });
    }

    loadMoreVideos () {
        const showVideos = this.videos.slice(0, 3);

        this.appendVideos(showVideos);

        if (this.videos.length > 3) {
            this.videos = this.videos.slice(3, this.videos.length);
        } else {
            this.$pager.parentNode.removeChild(this.$pager);
        }
    }

    appendVideos (videos) {
        videos.forEach(video => {
            const $video = createElementFromHTML(video);
            this.$pager.parentNode.insertBefore($video, this.$pager);

            const contentUpdateEvent = new CustomEvent('content.loaded', {
                view: window,
                bubbles: true,
                cancelable: false,
                detail: {
                    $context: $video
                }
            });

            window.dispatchEvent(contentUpdateEvent);
        });
    }
}

export { Video };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$video = $context.querySelectorAll('[data-video="root"]');

        for (let i = 0; i < $$video.length; i++) {
            const video = new Video($$video[i]);
            video.initialize();
        }
    }
});
